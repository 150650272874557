import React from 'react'; 

import SlugPage from './SlugPage';
import { Route, Routes } from "react-router-dom";

function App() {
  return (

      <Routes>
        {/* <Route path="/" element={<SlugPage />} /> */}
        <Route exact path="*" element={<SlugPage />} />
      </Routes>

  );
}

export default App;
