import React from 'react';
import { useEffect } from 'react';
function SlugPage(){
//  const currentUrl ="https://gopnp.link/hell";
  const currentUrl = window.location.href;
  const alternative = "https://aventoz.com";

  const requestOptions = {
    method: "GET",
    redirect: "follow"
  };

  const getdate = async () => {
    try {
      const response = await fetch(`https://aventoz.net/api/check_link_redirect?shortlink_slug=${currentUrl}&alternative=${alternative}`, requestOptions);
      const result = await response.json();
      window.location.replace(result.url);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getdate();
  }, []);
  return <></>;
};

export default SlugPage;
